import GameOfLife from "./GameOfLife"
import { useSpring, animated } from "react-spring"
import {useHistory} from 'react-router-dom'
import {useState, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleLeft, faPlay, faPause, faEraser} from '@fortawesome/free-solid-svg-icons'


const ResetButton = ({onClick}) =>{
    return (
            <div className="ml-3 cursor-pointer" onClick={onClick}>
              <FontAwesomeIcon className="mx-3 transition duration-500 transform hover:scale-125 cursor-pointer" icon={faEraser} size="1x"></FontAwesomeIcon>
              <span className="uppercase font-mono">
                   clear all
              </span>
            </div>
    )
}


const ControlBar = ({setTargetOpacity, play, setPlay, reset}) => {
    const navigateBack = () => {
      setTargetOpacity(0)
    }

    return (
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-black shadow-2xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex">
            <div className="font-mono font-semibold  flex flex-grow text-white" >
            <div onClick={navigateBack} className="cursor-pointer">
              <FontAwesomeIcon className="mx-3 transition duration-500 transform hover:scale-125 cursor-pointer" icon={faAngleDoubleLeft} size="1x"></FontAwesomeIcon>
              <span className="uppercase font-mono">
                Back to homepage
              </span>
            </div>
            <div className="ml-auto cursor-pointer" onClick={()=>setPlay((play) ? false : true)}>
              <FontAwesomeIcon className="mx-3 transition duration-500 transform hover:scale-125 cursor-pointer" icon={(play) ? faPause : faPlay} size="1x"></FontAwesomeIcon>
              <span className="uppercase font-mono">
                  {(play)? "pauze and interact": "play"}
              </span>
            </div>
            {(!play) ? <ResetButton onClick={reset}></ResetButton>: null}
          </div>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (false ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >

          </div>
        </div>
      </nav>
    )
}


const InteractiveGameOfLife = () => {
    const history = useHistory()
    const [play, setPlay] = useState(true)
    const [targetOpacity, setTargetOpacity] = useState(1)
    const resetRef = useRef(()=>{})
    const reset = () => {
        resetRef.current()
    }
    const animationProps = useSpring({ to: { opacity: targetOpacity }, from: { opacity: targetOpacity ? 0: 1 }, delay: 200,
        onRest: () => {
            if (!targetOpacity) { // if we fade out, we want to navigate back to the homepage
                history.push('/')
            }
        }})
    return (
        <animated.div style={animationProps}>

            <div className="fixed w-full z-10" style={animationProps}>
                <ControlBar play={play} reset={reset} setPlay={setPlay} setTargetOpacity={setTargetOpacity}/>
            </div>
            <div className="fixed w-full">
            <GameOfLife play={play} resetRef={resetRef}></GameOfLife>
        </div>
        <div>
        </div>
    </animated.div>
    )
}
export default InteractiveGameOfLife