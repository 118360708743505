import './App.css';
import Hero from './Hero.js'
import GameOfLife from './GameOfLife';
import InteractiveGameOfLife from './InteractiveGameOfLife';
import CV from './CV'
import {useState, useEffect} from 'react'
import ProjectWrapper from './ProjectWrapper'
import { useSpring, animated } from 'react-spring';
import { faVirus, faCube, faBicycle} from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from './ScrollToTop';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const projects = [
  {
    projectId: "1",
    title: 'COVID pandemic model',
    description: "In this project I explore the outbreak of the Coronavirus in the Netherlands using a simple toy model written in Python",
    srcFile: 'markdowns/corona.md', 
    heroIcon: faVirus,
    githubLink: 'https://github.com/danjer/corona'
  },
  {
    projectId: "2",
    title: 'django-stapler',
    description: "django-stapler is a reuseable Django app that allows you to combine ModelForms. This way you can easily create and update new Model instances in one view",
    srcFile: 'markdowns/stapler.md', 
    heroIcon: faCube,
    githubLink: 'https://github.com/danjer/django-stapler'
  },  
  {
    projectId: "3",
    title: 'Predicting the winning time of the tour de france',
    description: `In this project we gather data about the tour de france from wikipedia using SPARQL. 
    We than use the data to construct a simple model that we can use to predict the winning time of the tour.`,
    srcFile: 'notebooks/wikidata.json', 
    heroIcon: faBicycle,
    githubLink: 'https://github.com/danjer/wikidata'
  },

  {
    projectId: "4",
    title: 'Pickle Rick!',
    description: `In this project we explore how one can exploit the arbitrary code execution that is insecure and inherent to pickle.`,
    srcFile: 'markdowns/pickle_exploit.md', 
    githubLink: 'https://github.com/danjer/pickle_rick'
  },
    {
    projectId: "5",
    title: 'Cracking the Enigma using Apache Spark',
    description: `In this project I show how you can use Apache Spark to crack the Enigma, a machine used by the Germans to encrypt secret messages.`,
    srcFile: 'notebooks/cracking_enigma.json',
    heroIcon: faBicycle,
    githubLink: 'https://github.com/danjer/enigma'
  },
]


function App() {
  const [hero, setHero] = useState()
  const targetOpacity = 1
  const animationProps = useSpring({ to: { opacity: targetOpacity }, from: { opacity: targetOpacity ? 0 : 1 }, delay: 200 })
  useEffect(()=>{
    setHero(<Hero projects={projects} setHero={setHero}></Hero>)
  }, [])
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route path="/cv">
          <CV></CV>
        </Route>
        <Route path="/game-of-life">
          <animated.div style={animationProps} className="App relative">
            <InteractiveGameOfLife></InteractiveGameOfLife>
          </animated.div>
        </Route>
        <Route path="/projects/:projectId">
            <ProjectWrapper projects={projects}></ProjectWrapper>
        </Route>
        <Route path="/">
          <animated.div style={animationProps} className="App relative">
            <div className="fixed w-full">
                <GameOfLife></GameOfLife>
            </div>
            <div>
                {hero}
            </div>
          </animated.div>
        </Route>
      </Switch>
    </Router>

  )
}

export default App;
