import "./GameOfLife.css"
import {useState, useEffect} from 'react'
import useInterval from "./hooks/useInterval"

const CELL_SIZE = 20 // the size of a cell in pixels
const SPEED = 1000

function getRandomInt(max) {
    return Math.floor(Math.random() * max)
  }
  
const getNeighbourIndices = (cell, width, height) => {
    // if (!cell || !width || !height) return new Error('grid-neighbors: Incorrect input!');

    cell = parseInt(cell);
    width = parseInt(width);
    height = parseInt(height);

    const SIZE = width * height; // Total cells

    if (SIZE < 9) return new Error(`grid-neighbors: Minimum grid size is 9 cells. Provided grid is ${SIZE} cells.`);
    if (cell >= SIZE) return new Error(`grid-neighbors: Cell reference "${cell}" out of bounds. Maximum reference is ${SIZE - 1}.`);

    // Setup
    const LC = Math.floor(cell / width) * width;  // left most cell
    const RC = (LC + width) - 1;                  // right most cell
    const SIZE_MINUS_WIDTH = SIZE - width;
    const CELL_MINUS_WIDTH = cell - width;
    const CELL_PLUS_WIDTH = cell + width;
    const CELL_MOD_WIDTH = cell % width;
    const TOP_RIGHT = width - 1;
    const BOTTOM_RIGHT = SIZE - 1;

    // Directions
    let north;
    let south;
    let east;
    let west;
    let northWest;
    let northEast;
    let southEast;
    let southWest;

    // North
    if (CELL_MINUS_WIDTH < 0) {                   // TOP EDGE
        north = SIZE_MINUS_WIDTH + cell;
    } else {
        north = CELL_MINUS_WIDTH;
    }

    // South
    if (CELL_PLUS_WIDTH >= SIZE) {                // BOTTOM EDGE
        south = cell - LC;
    } else {
        south = CELL_PLUS_WIDTH;
    }

    // East, North-East, South-East
    if (CELL_MOD_WIDTH === TOP_RIGHT) {           // RIGHT EDGE
        east = LC;
        if (cell === TOP_RIGHT) {                   // top right corner
        northEast = SIZE_MINUS_WIDTH;
        southEast = LC + width;
        } else if (cell === BOTTOM_RIGHT) {         // bottom right corner
        northEast = LC - width;
        southEast = 0;
        } else {
        northEast = LC - width;
        southEast = LC + width;
        }
    } else {
        east = cell + 1;
        northEast = north + 1;
        southEast = south + 1;
    }

    // West, North-West, South-West
    if (CELL_MOD_WIDTH === 0) {                   // LEFT EDGE
        west = RC;
        if (cell === SIZE_MINUS_WIDTH) {            // bottom left corner
        northWest = cell - 1;
        southWest = TOP_RIGHT;
        } else if (cell === 0) {                    // top left corner
        northWest = BOTTOM_RIGHT;
        southWest = RC + width;
        } else {
        northWest = cell - 1;
        southWest = RC + width;
        }
    } else {
        west = cell - 1;
        northWest = north - 1;
        southWest = south - 1;
    }

    return [northWest, north, northEast, west, east, southWest, south, southEast];
    };

const getDimensions = () => {
    return {
        width: Math.floor(window.innerWidth/CELL_SIZE),
        height: Math.floor(window.innerHeight/CELL_SIZE),
    }
}

const Cell = ({cell, play=true, setAutomate}) => {
    const {x, y, isAlife} = cell
    const toggleState = () => {
            setAutomate((array)=>{
                const {width} = getDimensions()
                const index = width * y + x
                const nwArray = [...array]
                nwArray[index].isAlife = !isAlife
                return nwArray
            })
    }
    if (play){
        return (<div className={(cell.isAlife) ? "bg-life gol-cell": "bg-white gol-cell"}></div>)
    }
    else{
        return (<div onClick={toggleState} className={(cell.isAlife) ? "bg-life gol-cell cursor-pointer transform hover:scale-125 ": "bg-white gol-cell transform hover:scale-125 cursor-pointer"}></div>)
    }
}

export default function GameOfLife({play=true, resetRef}){

    const initAutomate = ({width, height}) => {
        var automate = []
        for (var i = 0; i < height; i ++){
            for (var j = 0; j < width; j ++){
                automate.push({y: i, x:j, isAlife: (getRandomInt(7) === 1)? true: false})
             }
        }
        return automate
    }
    const [automate, setAutomate] = useState(()=>initAutomate(getDimensions()))
    if (resetRef ){
        resetRef.current = ()=>setAutomate((oldAutomate) => oldAutomate.map((cell) => {return {...cell, isAlife:false}}))
    }


    // for updating the amount of cells
    useEffect(() => {
        const eventHandler = () => {
            setAutomate(initAutomate(getDimensions()));
        }
        window.addEventListener('resize', eventHandler)
        return (()=> window.removeEventListener('resize', eventHandler))
    }, [])
    const renderAutomate = (automate) =>{
        var cp = [...automate]
        let {width} = getDimensions()
        var reshaped = []
        while(cp.length) reshaped.push(cp.splice(0, width));
        return reshaped.map((r)=>{
            return <div className="flex-row flex">{r.map((c) =><Cell cell={c} play={play} setAutomate={setAutomate}></Cell>)}</div>
        })}

    const getNeighbours = (cell, automate) =>{
        let {width, height} = getDimensions()
        let {x, y} = cell
        let indices = getNeighbourIndices(y*width+x, width, height)
        return indices.map((index)=>automate[index])
    }
    const getNewStates = (automate) => {
        let oldAutomate = [...automate]
        return automate.map((cell) => {
            var lnbs = getNeighbours(cell, oldAutomate).filter((cell)=>cell.isAlife)
            if (cell.isAlife){
                if(lnbs.length === 2 || lnbs.length === 3){
                    return {...cell}
                }
                else{
                    return {...cell, isAlife: false}
                }
            }
            else{
                if (lnbs.length === 3) {
                    return {...cell, isAlife: true}
                }
                else{
                    return {...cell}
                }
            }

        }

        )
    }
    useInterval(()=>{setAutomate(getNewStates(automate))}, (play)? SPEED: null)
    return (<div className="h-screen flex overflow-hidden opacity-40">
        <div className="m-auto flex flex-wrap">
             {renderAutomate(automate)}
        </div>
    </div>)
}