import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons'


const Navbar = ({setTargetOpacity}) => {
    const navigateBack = () => {
      setTargetOpacity(0)
    }

    return (
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-black shadow-2xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="font-mono font-semibold cursor-pointer  text-white" onClick={navigateBack}>
            <div>
              <FontAwesomeIcon className="mx-3 transition duration-500 transform hover:scale-125 cursor-pointer" icon={faAngleDoubleLeft} size="1x"></FontAwesomeIcon>
              <span className="uppercase font-mono">
                Back to homepage
              </span>
            </div>
          </div>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (false ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >

          </div>
        </div>
      </nav>
    )
}

export default Navbar