import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub, faPython } from '@fortawesome/free-brands-svg-icons'
import { faGraduationCap, faDna } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated, config } from 'react-spring'
import {
    Link as RouterLink,
} from "react-router-dom"

import ProjectHero from './ProjectHero';

const Hero = ({ heroHeader = 'WELCOME TO PIECES OF PY!', projects, setHero, collapsed = true}) => {
    const [header, setHeader] = useState(heroHeader)
    const prevHeaderRef = useRef()
    const [showProjects, setShowProjects] = useState(!collapsed)
    const toggle = () => { setShowProjects(!showProjects) }
    const slideInStyles = useSpring({
        config: { ...config.gentle },
        from: { opacity: 0, height: showProjects ? "auto" : 0 },
        to: {
            opacity: showProjects ? 1 : 0,
            height: showProjects ? "auto" : 0
        }

    });
    const mainFadeIn = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: 200, config: config.gentle })
    const resetHeader = () => setHeader(heroHeader)
    const projectDropdown = (projects) => {
        return projects.map((project) => <li onClick={() => setHero(<ProjectHero setHero={setHero} {...project} projects={projects}></ProjectHero>)} className="cursor-pointer">* {project.title} </li>)
    }
    useEffect(() => {
        prevHeaderRef.current = header
    })

    return (
        <animated.div style={mainFadeIn} className="min-h-screen p-5 sm:p-10 mb-3 flex flex-col">
            <div className="my-auto">
                <div className="transition duration-1000 md:w-5/6 w-full max-w-full lg:p-12 m-auto relative transform hover:scale-105">
                    <div className="bg-black lg:absolute left-0 top-0 p-5 w-full lg:w-1/2 flex-row flex">
                        <span className="text md:text-3xl text-3xl text-white font-sans inline-block mr-10">>_ </span>
                        <h1 className="md:text-3xl text-3xl text-white font-mono">{header}</h1>
                    </div>

                    <div className="p-10 bg-white shadow-2xl text-left font-medium">

                        {/* <img className="border-2 h-32 w-32 absolute top-0 right-0 md:block hidden border-black-200 border-black" src="https://media-exp1.licdn.com/dms/image/C4D03AQHtd7WhvVdbrg/profile-displayphoto-shrink_200_200/0/1539348592637?e=1632960000&v=beta&t=R_ck25I5RdSGf_8_C0g4zHxendAQsbNHHbIjPJwY6CQ" alt=""></img> */}
                        <p className="md:mt-20 mt-3 font-mono font-semibold">Hey! I'm Daan van den Akker. Currently I'm working as python developer. I have a broad interest in different techniques and subjects. I mainly focus on python and its use in data analysis. On this site I will share some of my projects. Enjoy!</p>
                        <div className="mt-10 flex flex-wrap flex-row-reverse">
                            <div className="m-auto">
                                <a href="http://www.linkedin.com/in/dhvdakker">
                                    <FontAwesomeIcon className="mx-1 md:mx-5 transition duration-500 transform hover:scale-125" icon={faLinkedinIn} size="2x"
                                        onMouseEnter={() => setHeader("LINKEDIN PROFILE")} onMouseLeave={resetHeader}></FontAwesomeIcon>
                                </a>
                                <a href="https://github.com/danjer">
                                    <FontAwesomeIcon className="mx-1 md:mx-5 transition duration-500 transform hover:scale-125" icon={faGithub} onMouseEnter={() => setHeader("GITHUB PROFILE")} onMouseLeave={resetHeader}
                                        size="2x"></FontAwesomeIcon>
                                </a>
                                <FontAwesomeIcon className="mx-1 md:mx-5 transition duration-500 transform hover:scale-125 cursor-pointer" icon={faPython} onMouseEnter={() => setHeader("PYTHON PROJECTS")}
                                    onMouseLeave={resetHeader} onClick={toggle}
                                    size="2x"></FontAwesomeIcon>
                                <RouterLink to="/cv">
                                    <FontAwesomeIcon className="mx-1 md:mx-5 transition duration-500 transform hover:scale-125" icon={faGraduationCap} onMouseEnter={() => setHeader("CURRICULUM VITAE")} onMouseLeave={resetHeader}
                                        size="2x"></FontAwesomeIcon>
                                </RouterLink>
                                <RouterLink to="/game-of-life">
                                    <FontAwesomeIcon className="mx-1 md:mx-5 transition duration-500 transform hover:scale-125" icon={faDna} onMouseEnter={() => setHeader("PlAY CONWAY'S GAME OF LIFE")} onMouseLeave={resetHeader}
                                        size="2x"></FontAwesomeIcon>
                                </RouterLink>

                            </div>
                        </div>
                        <animated.div style={{ ...slideInStyles }}>
                            <div className="mt-4">
                                <div className="w-full p-3 mt-10 font-mono font-bold bg-black text-white whitespace-nowrap overflow-hidden overflow-x-auto" >
                                    <ul>
                                        {projectDropdown(projects)}
                                    </ul>
                                </div>
                            </div>
                        </animated.div>


                    </div>
                </div>

            </div>
        </animated.div>
    )
}


export default Hero