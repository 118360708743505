import Project from "./Project";
import {useParams} from "react-router-dom";

const ProjectWrapper = ({projects})=>{
    let {projectId} = useParams()
    let [project] = projects.filter((p)=>p.projectId === projectId)
    return (
        <Project path={project.srcFile}></Project>
    )
}

export default ProjectWrapper