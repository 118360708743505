import GameOfLife from "./GameOfLife";
import Navbar from "./Navbar"
import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect, useCallback } from 'react';
import rehypeRaw from 'rehype-raw'
import './Project.css';
import { useSpring, animated } from 'react-spring'
import JupyterViewer from "react-jupyter-notebook";
import rehypePrism from '@mapbox/rehype-prism'
import { useHistory } from "react-router-dom";
import "./prism.css";

const MARKDOWN = "MARKDOWN"
const NOTEBOOK = "NOTEBOOK"


const Project = ({ path = "" }) => {
    const contentType = path.endsWith('.md') ? MARKDOWN : NOTEBOOK
    const [content, setContent] = useState("")
    const [targetOpacity, setTargetOpacity] = useState(1)
    const [showNav, setShowNav] = useState(true)
    const [y, setY] = useState(window.scrollY);
    const history = useHistory()
    const animationProps = useSpring({
        to: { opacity: targetOpacity }, from: { opacity: targetOpacity ? 0 : 1 }, delay: 200,
        onRest: () => {
            if (!targetOpacity) { // if we fade out, we want to navigate back to the homepage
                history.push('/')
            }
        }
    })


    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setShowNav(true)
            } else if (y < window.scrollY) {
                setShowNav(false)
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    useEffect(() => {
        if (contentType === MARKDOWN) {
            fetch(process.env.PUBLIC_URL + "/" + path)
                .then(response => response.text())
                .then((raw) => {
                    setContent(raw)
                })
                .catch(err => console.log(err))
        }
        else {
            fetch(process.env.PUBLIC_URL + "/" + path)
                .then(response => response.json())
                .then((raw) => {
                    setContent(raw)
                })
                .catch(err => console.log(err))
        }
    }, [contentType, path])
    return (
        <animated.div style={animationProps}>
            <div className={showNav ? "fixed w-full z-10 visible" : "fixed w-full z-10 invisible"}>
                <Navbar setTargetOpacity={setTargetOpacity} />
            </div>
            <div className="fixed w-full">
                <GameOfLife></GameOfLife>
            </div>
            {content ?
                <div>
                    <div className="m-auto w-11/12 md:w-2/3 bg-white relative top-24 mb-36 font-mono shadow-2xl p-10">
                        {(contentType === MARKDOWN) ? <ReactMarkdown rehypePlugins={[rehypePrism, rehypeRaw]} children={content} escapeHtml={false} ></ReactMarkdown> : <JupyterViewer
                            displaySource="show" mediaAlign="center" displayOutput="show" rawIpynb={content}></JupyterViewer>}
                    </div>
                </div>
                :
                null
            }
        </animated.div>
    )

}

export default Project
