import { faPython, faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Element } from 'react-scroll'
import { useSpring, animated, config } from 'react-spring'
import { faTerminal, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { Link as RouterLink } from "react-router-dom";
import Hero from './Hero'

const ProjectHero = ({ title, projectId, scrollTarget, description, heroIcon = faPython, githubLink, scrollIcon, projects, setHero }) => {
    const header = title
    const content = description
    const mainFadeIn = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: 200, config: config.gentle })
    const NavigateBack = () => setHero(<Hero projects={projects} setHero={setHero} collapsed={false}></Hero>)
    return (
        <Element name={String(projectId)} className="h-screen p-5 sm:p-10  flex flex-col">
            <animated.div className="my-auto" style={mainFadeIn}>
                <div className="group transition duration-1000 md:w-5/6 w-full md:p-12 m-auto relative transform hover:scale-105">
                    <div className="bg-black lg:absolute left-0 top-0 p-5 w-full lg:w-3/4 flex-row flex">
                        <span className="hidden md:visible text text-3xl text-white font-mono inline-block mr-10">>_ </span>
                        <h1 className="text-white text-3xl font-mono uppercase">{header}</h1>
                    </div>

                    <div className="p-10 bg-white shadow-2xl border-black border-solid border- text-left font-medium">
                        {/* <div className="p-2 absolute bg-white border-black border-solid border3 mx-5 top-0 right-0 transition duration-500 transform lg:block hidden">
                            <FontAwesomeIcon icon={heroIcon} size="6x" />
                        </div> */}
                        <p className="md:mt-20 font-mono font-semibold">
                            {content}

                        </p>
                        <div className="mt-10 flex font-mono font-semibold flex-wrap flex-row-reverse">
                            <div className="m-auto flex flex-wrap justify-center">
                                <div to={"/projects/" + projectId} onClick={NavigateBack} className="cursor-pointer mx-4 my-2 transition duration-500 transform hover:scale-105">
                                    <FontAwesomeIcon className="mx-3   cursor-pointer" icon={faAngleDoubleLeft} size="1x"></FontAwesomeIcon>
                                   all projects 
                                </div>
                                <a href={githubLink} className="mx-4 my-2 transition duration-500 transform hover:scale-105  cursor-pointer">
                                    <FontAwesomeIcon className="mx-3 " icon={faGithub} size="1x"></FontAwesomeIcon>
                                    view on github
                                </a>
                                <RouterLink onClick={NavigateBack} to={"/projects/" + projectId} className="transition duration-500 transform hover:scale-105  cursor-pointe rmx-4 my-2">
                                    <FontAwesomeIcon className="mx-3 " icon={faTerminal} size="1x"></FontAwesomeIcon>
                                    project page
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
            <Link className="invisible md:visible" to={String(scrollTarget)} smooth={"easeInOutQuad"} duration={1500}>
                <div className="mt-auto mb-10">
                    <FontAwesomeIcon className="mx-2 transition duration-500 transform hover:scale-125  cursor-pointer" icon={scrollIcon} size="2x"></FontAwesomeIcon>
                </div>
            </Link>
        </Element>
    )
}

export default ProjectHero